import update from "immutability-helper"
import { ADD_USER_FAILED, ADD_USER_START, ADD_USER_SUCCESS, DELETE_USER_FAILED, DELETE_USER_START, DELETE_USER_SUCCESS, EDIT_USER_FAILED, EDIT_USER_START, EDIT_USER_SUCCESS, GET_ACCOUNT_LIST_FAILED, GET_ACCOUNT_LIST_START, GET_ACCOUNT_LIST_SUCCESS, GET_ACTIVITY_LOG_FAILED, GET_ACTIVITY_LOG_START, GET_ACTIVITY_LOG_SUCCESS, GET_DETAIL_USER_FAILED, GET_DETAIL_USER_START, GET_DETAIL_USER_SUCCESS, GET_ROLE_LIST_FAILED, GET_ROLE_LIST_START, GET_ROLE_LIST_SUCCESS, GET_USER_LIST_FAILED, GET_USER_LIST_START, GET_USER_LIST_SUCCESS, RESET_STATE } from "../actions/actionType"

const initialState = {
    accountList: {
        data: [],
        message: "",
        isLoading: false
    },
    activityLog: {
        data: [],
        pagination: {},
        message: "",
        isLoading: false
    },
    deleteUser: {
        isLoading: false,
        message: ""
    },
    editUser: {
        isLoading: false,
        message: "",
    },
    detailUser: {
        data: {},
        isLoading: false,
        message: ""
    },
    addUser: {
        isLoading: false,
        message: ""
    },
    userList: {
        data: {},
        message: false,
        isLoading: false
    },
    roleList: {
        data: [],
        message: false,
        isLoading: false
    }
}

function accountReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ACCOUNT_LIST_START:
            return update(state, {
                accountList: {
                    data: { $set: [] },
                    message: { $set: "" },
                    isLoading: { $set: true }
                }
            })
        case GET_ACCOUNT_LIST_SUCCESS:
            return update(state, {
                accountList: {
                    data: { $set: action.payload },
                    isLoading: { $set: false }
                }
            })
        case GET_ACCOUNT_LIST_FAILED:
            return update(state, {
                accountList: {
                    data: { $set: [] },
                    message: { $set: action.payload },
                    isLoading: { $set: false }
                }
            })
        case GET_ACTIVITY_LOG_START:
            return update(state, {
                activityLog: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                    data: { $set: [] },
                    pagination: { $set: {} }
                }
            })
        case GET_ACTIVITY_LOG_SUCCESS:
            return update(state, {
                activityLog: {
                    isLoading: { $set: false },
                    data: { $set: action.payload.data },
                    pagination: { $set: action.payload.page_meta }
                }
            })
        case GET_ACTIVITY_LOG_FAILED:
            return update(state, {
                activityLog: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case DELETE_USER_START:
            return update(state, {
                deleteUser: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                }
            })
        case DELETE_USER_SUCCESS:
            return update(state, {
                deleteUser: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        case DELETE_USER_FAILED:
            return update(state, {
                deleteUser: {
                    isLoading: { $set: false },
                    message: { $set: action.payload },
                }
            })
        case EDIT_USER_START:
            return update(state, {
                editUser: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                }
            })
        case EDIT_USER_SUCCESS:
            return update(state, {
                editUser: {
                    isLoading: { $set: false },
                    message: { $set: action.payload },
                }
            })
        case EDIT_USER_FAILED: {
            return update(state, {
                editUser: {
                    isLoading: { $set: false },
                    message: { $set: action.payload },
                }
            })
        }
        case GET_DETAIL_USER_START: {
            return update(state, {
                detailUser: {
                    isLoading: { $set: true },
                    data: { $set: {} },
                    message: { $set: "" }
                }
            })
        }
        case GET_DETAIL_USER_SUCCESS: {
            return update(state, {
                detailUser: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        }
        case GET_DETAIL_USER_FAILED: {
            return update(state, {
                detailUser: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        }
        case ADD_USER_START: {
            return update(state, {
                addUser: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                }
            })
        }
        case ADD_USER_SUCCESS: {
            return update(state, {
                addUser: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        }
        case ADD_USER_FAILED: {
            return update(state, {
                addUser: {
                    isLoading: { $set: false },
                    message: { $set: action.payload },
                }
            })
        }
        case GET_USER_LIST_START: {
            return update(state, {
                userList: {
                    isLoading: { $set: true },
                    message: { $set: "" },
                    data: {$set : []}
                }
            })
        }
        case GET_USER_LIST_SUCCESS: {
            return update(state, {
                userList: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        }
        case GET_USER_LIST_FAILED: {
            return update(state, {
                userList: {
                    isLoading: { $set: false },
                    message: { $set: action.payload },
                    data: {$set : []}
                }
            })
        }
        case GET_ROLE_LIST_START: {
            return update(state, {
                roleList: {
                    isLoading: { $set: true },
                    message: { $set: "" }
                }
            })
        }
        case GET_ROLE_LIST_SUCCESS: {
            return update(state, {
                roleList: {
                    isLoading: { $set: false },
                    data: { $set: action.payload }
                }
            })
        }
        case GET_ROLE_LIST_FAILED: {
            return update(state, {
                roleList: {
                    isLoading: { $set: false },
                    message: { $set: action.payload }
                }
            })
        }
        case RESET_STATE: {
            return update(state, {
                addUser: {
                    isLoading: { $set: false },
                    message: { $set: "" },
                    isSucceed: { $set: false },
                    isFailed: { $set: false }
                },
                editUser: {
                    isLoading: { $set: false },
                    message: { $set: "" },
                    isSucceed: { $set: false },
                    isFailed: { $set: false }
                },
                deleteUser: {
                    isLoading: { $set: false },
                    message: { $set: "" },
                    isSucceed: { $set: false },
                    isFailed: { $set: false }
                }
            })
        }
        default:
            return state
    }
}


export default accountReducer