import moment from "moment"


function formatDate(date, time) {

    if (time && date) return moment(date).format('DD / MM / YYYY - HH:mm')
    else if (date) return moment(date).format('DD/MM/YYYY')
    else return moment().format('DD/MM/YYYY')
}

export default formatDate;