import update from 'immutability-helper';
import { GET_BENEFICIARIES_SUCCESS, GET_TEMP_BENEFICIARIES, GET_BENEFICIARIES_START, GET_BENEFICIARIES_FAILED, GET_ALL_BENEFICIARIES_FAILED, GET_ALL_BENEFICIARIES_START, GET_ALL_BENEFICIARIES_SUCCESS, DELETE_BENEFICIARIES_START, DELETE_BENEFICIARIES_SUCCESS, DELETE_BENEFICIARIES_FAILED, ADD_BENEFICIARY_T0_GROUP_START, ADD_BENEFICIARY_TO_GROUP_SUCCESS, ADD_BENEFICIARY_TO_GROUP_FAILED, GET_BENEFICIARIES_BY_GID_START, GET_BENEFICIARIES_BY_GID_SUCCESS, GET_BENEFICIARIES_BY_GID_FAILED, INQUIRY_START, INQUIRY_SUCCESS, INQUIRY_FAILED, INQUIRY_RESET, POST_BENEFICIARY_SUCCESS, POST_BENEFICIARY_START, POST_BENEFICIARY_FAILED, POST_BENEFICIARY_CLEAR_STATE, DOWNLOAD_EXCEL_FORMAT_START, DOWNLOAD_EXCEL_FORMAT_SUCCESS, DOWNLOAD_EXCEL_FORMAT_FAILED, UPLOAD_EXCEL_START, UPLOAD_EXCEL_SUCCESS, UPLOAD_EXCEL_FAILED, DOWNLOAD_INVALID_NUMBER_START, DOWNLOAD_INVALID_NUMBER_SUCCESS, DOWNLOAD_INVALID_NUMBER_FAILED, UPLOAD_INQUIRY_SUCCESS, UPLOAD_INQUIRY_FAILED, UPLOAD_INQUIRY_RESET, EDIT_BENEFICIARY_START, EDIT_BENEFICIARY_SUCCESS, EDIT_BENEFICIARY_FAILED, EDIT_BENEFICIARY_RESET, GET_ID_BENEFICIARY, GET_MASTER_BANK_START, GET_MASTER_BANK_SUCCESS, GET_MASTER_BANK_FAILED, GET_ALL_BENEFICIARIES_CLEAR, INQUIRY_KEY, GET_BENEF_BY_GROUP_START, GET_BENEF_BY_GROUP_SUCCESS, GET_BENEF_BY_GROUP_FAILED } from '../actions/actionType';

const initialState = {
  benefList: {
    current_page: 0,
    data: [],
    limit: 0,
    next_page: 0,
    prev_page: 0,
    total_page: 0,
    total_record: 0
  },
  benefListbyGId: {
    current_page: 0,
    data: [],
    limit: 0,
    next_page: 0,
    prev_page: 0,
    total_page: 0,
    total_record: 0
  },
  editBeneficiary: {
    isLoading: false,
    message: "",
    isSucceed: false,
    isFailed: false
  },
  benefId: [],
  benefListErrorMessage: "",
  benefListLoading: false,
  tempBeneficiaryList: {
    data: {},
    totalPage: 0,
    totalRecord: 0,
    page: 0
  },
  inquiryBeneficiary: {
    data: "",
    statusInquiry: "",
    inquiryKey: "",
    isSucceed: null,
    isLoading: false
  },
  tempBeneficiaryListLoading: false,
  exportBenef: {
    isLoading: false,
    isSucceed: null,
    message: ''
  },
  exportBenefMessage: "",
  deleteBeneficiariesStatus: "",
  deleteBeneficiariesLoading: false,
  deleteBeneficiariesMessage: "",
  addBeneficiaryGroupLoading: false,
  addBeneficiaryGroupStatus: "",
  addBeneficiaryGroupMessage: "",
  postBeneficiary: {
    isLoading: false,
    isSucceed: null,
    message: ""
  },
  downloadExcel: {
    isLoading: false,
    isSucceed: null,
    message: ""
  },
  uploadExcel: {
    isLoading: false,
    isSucceed: null,
    message: "",
    inquirySuccess: null,
    data: {}
  },
  downloadIvalidNumber: {
    isLoading: false,
    isSucceed: null,
    message: "",
    data: {}
  },
  masterBank: {
    isLoading: false,
    message: "",
    data: {}
  },
  benefByGroup: {
    isLoading: false,
    message: "",
    data: []
  }
}

function beneficiaryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BENEFICIARIES_START:
      return update(state, {
        benefListLoading: { $set: true },
        benefList: { $set: [] }
      })
    case GET_BENEFICIARIES_SUCCESS:
      return update(state, {
        benefListLoading: { $set: false },
        benefList: {
          current_page: { $set: action.payload.data.current_page },
          data: { $set: action.payload.data.data },
          limit: { $set: action.payload.data.limit },
          next_page: { $set: action.payload.data.next_page },
          prev_page: { $set: action.payload.data.prev_page },
          total_page: { $set: action.payload.data.total_page },
          total_record: { $set: action.payload.data.total_record }
        }
      });
    case GET_BENEFICIARIES_FAILED:
      return update(state, {
        benefListLoading: { $set: false },
        benefListErrorMessage: { $set: action.payload }
      })
    case GET_TEMP_BENEFICIARIES:
      return update(state, {
        tempData: { $set: action?.payload?.tempData },
        tempTotalRecord: { $set: action?.payload?.tempTotalRecord },
      });
    case "DEL_TEMP_BENEFICIARIES":
      return update(state, {
        tempData: { $set: action?.payload?.tempData },
        tempTotalRecord: { $set: action?.payload?.tempTotalRecord },
      });
    case GET_ALL_BENEFICIARIES_START:
      return update(state, {
        exportBenef: {
          isLoading: { $set: true },
          isSucceed: { $set: null }
        }
      })
    case GET_ALL_BENEFICIARIES_SUCCESS:
      return update(state, {
        exportBenef: {
          isLoading: { $set: false },
          isSucceed: { $set: true }
        }
      })
    case GET_ALL_BENEFICIARIES_CLEAR:
      return update(state, {
        exportBenef: {
          isLoading: { $set: false },
          isSucceed: { $set: null },
          message: { $set: '' }
        }
      })
    case GET_ALL_BENEFICIARIES_FAILED:
      return update(state, {
        exportBenef: {
          isLoading: { $set: false },
          isSucceed: { $set: false }
        }
      })
    case DELETE_BENEFICIARIES_START:
      return update(state, {
        deleteBeneficiariesLoading: { $set: true },
        deleteBeneficiariesStatus: { $set: false },
        deleteBeneficiariesMessage: { $set: "" }
      })
    case DELETE_BENEFICIARIES_SUCCESS:
      return update(state, {
        deleteBeneficiariesLoading: { $set: false },
        deleteBeneficiariesStatus: { $set: "success" }
      })
    case DELETE_BENEFICIARIES_FAILED:
      return update(state, {
        deleteBeneficiariesLoading: { $set: false },
        deleteBeneficiariesStatus: { $set: "failed" },
        deleteBeneficiariesMessage: { $set: action.payload }
      })
    case EDIT_BENEFICIARY_START:
      return update(state, {
        editBeneficiary: {
          isLoading: { $set: true },
          isFailed: { $set: false },
          isSucceed: { $set: false },
          message: { $set: "" }
        }
      })
    case EDIT_BENEFICIARY_SUCCESS:
      return update(state, {
        editBeneficiary: {
          isLoading: { $set: false },
          isSucceed: { $set: true },
          message: { $set: action.payload }
        }
      })
    case EDIT_BENEFICIARY_FAILED:
      return update(state, {
        editBeneficiary: {
          isLoading: { $set: false },
          message: { $set: action.payload },
          isFailed: { $set: true }
        }
      })
    case EDIT_BENEFICIARY_RESET:
      return update(state, {
        editBeneficiary: {
          isLoading: { $set: false },
          isFailed: { $set: false },
          isSucceed: { $set: false }
        }
      })
    case GET_ID_BENEFICIARY:
      return update(state, {
        benefId: { $set: action.payload }
      })
    case ADD_BENEFICIARY_T0_GROUP_START:
      return update(state, {
        addBeneficiaryGroupLoading: { $set: true },
        addBeneficiaryGroupMessage: { $set: "" },
        addBeneficiaryGroupStatus: { $set: "" }
      })
    case ADD_BENEFICIARY_TO_GROUP_SUCCESS:
      return update(state, {
        addBeneficiaryGroupLoading: { $set: false },
        addBeneficiaryGroupStatus: { $set: "success" },
        addBeneficiaryGroupMessage: { $set: action.payload }

      })
    case ADD_BENEFICIARY_TO_GROUP_FAILED:
      return update(state, {
        addBeneficiaryGroupLoading: { $set: false },
        addBeneficiaryGroupStatus: { $set: "failed" },
        addBeneficiaryGroupMessage: { $set: action.payload }
      })
    case GET_BENEFICIARIES_BY_GID_START:
      return update(state, {
        benefListLoading: { $set: true },
      })
    case GET_BENEFICIARIES_BY_GID_SUCCESS:
      return update(state, {
        benefListLoading: { $set: false },
        benefListbyGId: {
          current_page: { $set: action.payload.data.current_page },
          data: { $set: action.payload.data.data },
          limit: { $set: action.payload.data.limit },
          next_page: { $set: action.payload.data.next_page },
          prev_page: { $set: action.payload.data.prev_page },
          total_page: { $set: action.payload.data.total_page },
          total_record: { $set: action.payload.data.total_record }
        }
      })
    case GET_BENEFICIARIES_BY_GID_FAILED:
      return update(state, {
        benefListLoading: { $set: false },
        benefListErrorMessage: { $set: action.payload }
      })
    case INQUIRY_START:
      return update(state, {
        inquiryBeneficiary: {
          data: { $set: "" },
          isSucceed: { $set: null },
          isLoading: { $set: true }
        }
      })
    case INQUIRY_SUCCESS:
      return update(state, {
        inquiryBeneficiary: {
          data: { $set: action.payload },
          isSucceed: { $set: true },
          isLoading: { $set: false }
        }
      })
    case INQUIRY_FAILED:
      return update(state, {
        inquiryBeneficiary: {
          isSucceed: { $set: false },
          isLoading: { $set: false }
        }
      })
    case INQUIRY_KEY:
      return update(state, {
        inquiryBeneficiary: {
          inquiryKey: { $set: action.payload }
        }
      })
    case INQUIRY_RESET:
      return update(state, {
        inquiryBeneficiary: {
          data: { $set: "" },
          statusInquiry: { $set: "" },
          inquiryKey: { $set: "" },
          isSucceed: { $set: null },
          isLoading: { $set: false }
        }
      })
    case POST_BENEFICIARY_START:
      return update(state, {
        postBeneficiary: {
          isLoading: { $set: true },
          isSucceed: { $set: null },
          message: { $set: "" }
        }
      })
    case POST_BENEFICIARY_SUCCESS:
      return update(state, {
        postBeneficiary: {
          isLoading: { $set: false },
          isSucceed: { $set: true },
          message: { $set: action.payload }
        }
      })
    case POST_BENEFICIARY_FAILED:
      return update(state, {
        postBeneficiary: {
          isLoading: { $set: false },
          isSucceed: { $set: false },
          message: { $set: action.payload }
        }
      })
    case POST_BENEFICIARY_CLEAR_STATE:
      return update(state, {
        postBeneficiary: {
          isLoading: { $set: false },
          isSucceed: { $set: null },
          message: { $set: "" }
        }
      })
    case DOWNLOAD_EXCEL_FORMAT_START:
      return update(state, {
        downloadExcel: {
          isLoading: { $set: true },
          isSucceed: { $set: null },
          message: { $set: "" }
        }
      })
    case DOWNLOAD_EXCEL_FORMAT_SUCCESS:
      return update(state, {
        downloadExcel: {
          isLoading: { $set: false },
          isSucceed: { $set: true },
          message: { $set: action.payload }
        }
      })
    case DOWNLOAD_EXCEL_FORMAT_FAILED:
      return update(state, {
        downloadExcel: {
          isLoading: { $set: false },
          isSucceed: { $set: false },
          message: { $set: action.payload }
        }
      })
    case UPLOAD_EXCEL_START:
      return update(state, {
        uploadExcel: {
          isLoading: { $set: true },
          isSucceed: { $set: null },
          message: { $set: "" },
          data: { $set: {} },
          inquirySuccess: { $set: null }
        }
      })
    case UPLOAD_EXCEL_SUCCESS:
      return update(state, {
        uploadExcel: {
          isLoading: { $set: false },
          isSucceed: { $set: true },
          data: { $set: action.payload }
        }
      })
    case UPLOAD_INQUIRY_SUCCESS:
      return update(state, {
        uploadExcel: {
          inquirySuccess: { $set: true }
        }
      })
    case UPLOAD_INQUIRY_FAILED:
      return update(state, {
        uploadExcel: {
          inquirySuccess: { $set: false }
        }
      })
    case UPLOAD_INQUIRY_RESET:
      return update(state, {
        uploadExcel: {
          inquirySuccess: { $set: null }
        }
      })
    case UPLOAD_EXCEL_FAILED:
      return update(state, {
        uploadExcel: {
          isLoading: { $set: false },
          isSucceed: { $set: false },
          message: { $set: action.payload },
        }
      })
    case DOWNLOAD_INVALID_NUMBER_START: {
      return update(state, {
        isLoading: { $set: true },
        isSucceed: { $set: null },
        message: { $set: "" },
        data: { $set: {} }
      })
    }
    case DOWNLOAD_INVALID_NUMBER_SUCCESS: {
      return update(state, {
        isLoading: { $set: false },
        isSucceed: { $set: true },
        data: { $set: action.payload },
      })
    }
    case DOWNLOAD_INVALID_NUMBER_FAILED: {
      return update(state, {
        isLoading: { $set: false },
        isSucceed: { $set: false },
        message: { $set: action.payload }
      })
    }
    case GET_MASTER_BANK_START: {
      return update(state, {
        masterBank: {
          isLoading: { $set: true },
          message: { $set: "" },
          data: { $set: [] }
        }
      })
    }
    case GET_MASTER_BANK_SUCCESS: {
      return update(state, {
        masterBank: {
          isLoading: { $set: false },
          data: { $set: action.payload },
        }
      })
    }
    case GET_MASTER_BANK_FAILED: {
      return update(state, {
        masterBank: {
          isLoading: { $set: false },
          message: { $set: action.payload }
        }
      })
    }
    case GET_BENEF_BY_GROUP_START: {
      return update(state, {
        benefByGroup: {
          isLoading: { $set: true },
          message: { $set: "" }
        }
      })
    }
    case GET_BENEF_BY_GROUP_SUCCESS: {
      return update(state, {
        benefByGroup: {
          isLoading: { $set: false },
          data: { $set: action.payload }
        }
      })
    }
    case GET_BENEF_BY_GROUP_FAILED: {
      return update(state, {
        benefByGroup: {
          data: {$set : []},
          isLoading: { $set: false },
          message: { $set: action.payload }
        }
      })
    }
    default:
      return state;
  }
}

export default beneficiaryReducer;
