import update from 'immutability-helper';
import { LOGIN_START,LOGIN_SUCCESS,LOGIN_FAILED, UPDATE_CLIENT, EDIT_PROFILE, CLEAR_LOGIN_STATE, REGISTER_START, REGISTER_SUCCESS, REGISTER_FAILED, EDIT_PROFILE_START, EDIT_PROFILE_SUCCESS, EDIT_PROFILE_FAILED, GET_PROFILE_START, GET_PROFILE_SUCCESS, GET_PROFILE_FAILED, CHANGE_PASSWORD_START, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILED, IDLE_TIMEOUT_WARNING, IDLE_TIMEOUT_LOGOUT } from '../actions/actionType';

const initialState = {
    profile : {},
    loading : false,
    errorMessage : "",
    register : {
      loading : false,
      message : ""
    },
    idleTimeout : {
      logout : false,
      warning : false,
    }
}

function profileReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_START : 
      return update(state, {
        profile : {$set : {}},
        loading : {$set : true},
        errorMessage : {$set : ""}
      })
    case LOGIN_SUCCESS:
      return update(state, {
        profile: { $set: action.payload },
        loading: { $set : false }
      });
    case LOGIN_FAILED : 
      return update(state, {
        loading : {$set : false},
        errorMessage : {$set : action.payload}
      })
    case CLEAR_LOGIN_STATE :
      return update(state, {
        loading: {$set : false},
        profile : {$set : {}},
        errorMessage : {$set : ""}
      })
    case REGISTER_START :
        return update(state, {
          register : {
            loading : {$set : true},
            message : {$set : ""} 
          }
      })
    case REGISTER_SUCCESS :
        return update(state, {
          register : {
            loading : {$set : false},
          }
      })
    case REGISTER_FAILED : 
        return update(state, {
          register : {
            loading : {$set : false},
            message : {$set : action.payload}
          }
        })
    case EDIT_PROFILE:
      return update(state, {
        profile: { $set: action.payload },
      });
    case EDIT_PROFILE_START:
      return update(state,{
        loading : {$set : true},
        errorMessage : {$set : ''}
      })
    case EDIT_PROFILE_SUCCESS: 
      return update(state, {
        loading : {$set : false},
        profile: {$set : action.payload}
      })
    case EDIT_PROFILE_FAILED:
      return update(state, {
        loading : {$set : false},
        errorMessage : {$set : action.payload}
      })
    case GET_PROFILE_START:
      return update(state, {
        loading : {$set : true},
      })
    case GET_PROFILE_SUCCESS: 
      return update(state, {
        loading : {$set : false},
        profile : {$set : action.payload},
      })
    case GET_PROFILE_FAILED:
      return update(state, {
        loading : {$set : false},
        errorMessage : {$set : action.payload}
      })
    case UPDATE_CLIENT:
      return update(state, {
        profile: {
          Client: { $set: action.payload },
        },
      });
    case CHANGE_PASSWORD_START:
      return update(state, {
        loading : {$set : true},
        errorMessage : {$set : ''}
      })
    case CHANGE_PASSWORD_SUCCESS:
      return update(state, {
        loading : {$set : false},
      })
    case CHANGE_PASSWORD_FAILED:
      return update(state, {
        loading : {$set : false},
        errorMessage : {$set : action.payload}
      })
    case IDLE_TIMEOUT_WARNING:
      return update(state, {
        idleTimeout : {
          warning : {$set: action.payload}
        }
      })
    case IDLE_TIMEOUT_LOGOUT:
      return update(state, {
        idleTimeout: {
          logout : {$set : action.payload}
        }
      })
    default:
      return state;
  }
}

export default profileReducer;
