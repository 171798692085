import update from 'immutability-helper';
import {
  GET_SCHEDULER_LIST,
  GET_TEMP_BENEFICIARY_START,
  GET_TEMP_BENEFICIARY_SUCCESS,
  GET_TEMP_BENEFICIARY_FAILED,
  GET_SCHEDULER_LIST_START,
  GET_SCHEDULER_LIST_SUCCESS,
  GET_SCHEDULER_LIST_FAILED,
  GET_RETENTION_LIST_START,
  GET_RETENTION_LIST_SUCCESS,
  GET_RETENTION_LIST_FAILED,
  GET_INSTANT_LIST_START,
  GET_INSTANT_LIST_SUCCESS,
  GET_INSTANT_LIST_FAILED,
  APPROVE_TRANSACTION_START,
  APPROVE_TRANSACTION_SUCCESS,
  APPROVE_TRANSACTION_FAIELD,
  APPROVE_TRANSACTION_RESET,
  DELETE_TRANSACTION_START,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILED,
  GET_ALL_TRANSACTION_LIST_START,
  GET_ALL_TRANSACTION_LIST_SUCCESS,
  GET_ALL_TRANSACTION_LIST_FAILED,
  GET_ALL_TRANSACTION_LIST_CLEAR,
  GET_RETENTION_DETAIL,
  GET_TRANSFER_METHOD,
  GET_TRANSFER_METHOD_CLEAR,
  SAVE_TRANSFER_BENEFICIARY,
  SAVE_TRANSFER_BENEFICIARY_CLEAR,
  SAVED_BENEFICIARIES_TYPE,
  GET_TEMP_GROUP,
  GET_TEMP_GROUP_CLEAR,
  GET_DETAIL_TRANSACTION_START,
  GET_DETAIL_TRANSACTION_SUCCESS,
  GET_DETAIL_TRANSACTION_FAILED
} from '../actions/actionType';

const initialState = {
  scheduler: {
    schedulerList: [],
    schedulerListLoading: false,
    schedulerListMessage: "",
  },
  retention: {
    retentionList: [],
    retentionListLoading: false,
    retentionListMessage: ""
  },
  instant: {
    instantList: [],
    instantListLoading: false,
    instantListMessage: ""
  },
  detailTrx: {
    data: [],
    isLoading: false,
    message: ''
  },
  retentionDetail: {
    data: {}
  },
  deleteTransaction: {
    isLoading: false,
    message: ''
  },
  tempBeneficiary: {
    tempBeneficiaryList: {
      current_page: 0,
      data: [],
      limit: 0,
      next_page: 0,
      prev_page: 0,
      total_page: 0,
      total_record: 0
    },
    tempBeneficiaryLoading: false,
    tempBeneficiaryMessage: "",
    GETtempBeneficiaryStatus: ""
  },
  trfBeneficiary: {
    data: []
  },
  getTransferMethod: {
    trfMethod: []
  },
  approveTransaction: {
    isLoading: false,
    data: '',
    message: "",
    isSucceed: null
  },
  export: {
    isLoading: false,
    isSucceed: null,
    message: ''
  },
  savedBeneficiaryType: {
    type: 0
  },
  tempGroup: {
    data: []
  }
}

function payoutReducer(state = initialState, action) {
  switch (action.type) {
    // case GET_GROUP_LIST:
    //   return update(state, {
    //     payout: {
    //       groupList: { $set: action.payload },
    //     }
    //   });
    case GET_SCHEDULER_LIST:
      return update(state, {
        schedulers: {
          schedulerList: { $set: action.payload },
        }
      });
    case GET_SCHEDULER_LIST_START:
      return update(state, {
        scheduler: {
          schedulerListLoading: { $set: true },
          schedulerListMessage: { $set: "" },
          schedulerList: { $set: [] }
        }
      })
    case GET_SCHEDULER_LIST_SUCCESS:
      return update(state, {
        scheduler: {
          schedulerListLoading: { $set: false },
          schedulerList: { $set: action.payload },
          schedulerListMessage: { $set: "success" }
        }
      })
    case GET_SCHEDULER_LIST_FAILED:
      return update(state, {
        scheduler: {
          schedulerListLoading: { $set: false },
          schedulerListMessage: { $set: action.payload }
        }
      })
    case GET_RETENTION_LIST_START:
      return update(state, {
        retention: {
          retentionListLoading: { $set: true },
          retentionListMessage: { $set: '' },
          retentionList: { $set: [] }
        }
      })
    case GET_RETENTION_LIST_SUCCESS:
      return update(state, {
        retention: {
          retentionListLoading: { $set: false },
          retentionList: { $set: action.payload },
          retentionListMessage: { $set: "success" }
        }
      })
    case GET_RETENTION_LIST_FAILED:
      return update(state, {
        retention: {
          retentionListLoading: { $set: false },
          retentionListMessage: { $set: action.payload }
        }
      })
    case GET_INSTANT_LIST_START:
      return update(state, {
        instant: {
          instantListLoading: { $set: true },
          instantListMessage: { $set: '' },
          instantList: { $set: [] }
        }
      })
    case GET_INSTANT_LIST_SUCCESS:
      return update(state, {
        instant: {
          instantList: { $set: action.payload },
          instantListLoading: { $set: false },
        }
      })
    case GET_INSTANT_LIST_FAILED:
      return update(state, {
        instant: {
          instantListLoading: { $set: false },
          instantListMessage: { $set: action.payload }
        }
      })
    case GET_TEMP_BENEFICIARY_START:
      return update(state, {
        tempBeneficiary: {
          tempBeneficiaryLoading: { $set: true },
          GETtempBeneficiaryStatus: { $set: "" }
        }
      })
    case GET_TEMP_BENEFICIARY_SUCCESS:
      return update(state, {
        tempBeneficiary: {
          tempBeneficiaryList: {
            currentPage: { $set: action?.payload?.current_page },
            data: { $set: action?.payload?.data },
            limit: { $set: action?.payload?.limit },
            next_page: { $set: action?.payload?.next_page },
            prev_page: { $set: action?.payload?.prev_page },
            total_page: { $set: action?.payload?.total_page },
            total_record: { $set: action?.payload?.total_record }
          },
          tempBeneficiaryLoading: { $set: false },
          GETtempBeneficiaryStatus: { $set: "success" }
        }
      })
    case GET_TEMP_BENEFICIARY_FAILED:
      return update(state, {
        tempBeneficiary: {
          tempBeneficiaryLoading: { $set: false },
          tempBeneficiaryMessage: { $set: action.payload },
          GETtempBeneficiaryStatus: { $set: "failed" }
        }
      })
    case APPROVE_TRANSACTION_START:
      return update(state, {
        approveTransaction: {
          isLoading: { $set: true },
          message: { $set: "" },
          data: { $set: "" }
        }
      })
    case APPROVE_TRANSACTION_SUCCESS:
      return update(state, {
        approveTransaction: {
          data: { $set: action.payload },
          isLoading: { $set: false },
          isSucced: { $set: true }
        }
      })
    case APPROVE_TRANSACTION_FAIELD:
      return update(state, {
        approveTransaction: {
          isLoading: { $set: false },
          message: { $set: action.payload },
          isSucced: { $set: false }
        }
      })
    case APPROVE_TRANSACTION_RESET:
      return update(state, {
        approveTransaction: {
          isLoading: { $set: false },
          message: { $set: "" },
          data: { $set: '' },
          isSucced: { $set: null },
        }
      })
    case DELETE_TRANSACTION_START:
      return update(state, {
        deleteTransaction: {
          isLoading: { $set: true },
          message: { $set: '' }
        }
      })
    case DELETE_TRANSACTION_SUCCESS:
      return update(state, {
        deleteTransaction: {
          isLoading: { $set: false },
          message: { $set: action.payload }
        }
      })
    case DELETE_TRANSACTION_FAILED:
      return update(state, {
        deleteTransaction: {
          isLoading: { $set: false },
          message: { $set: action.payload }
        }
      })
    case GET_ALL_TRANSACTION_LIST_START:
      return update(state, {
        export: {
          isLoading: { $set: true },
          message: { $set: '' },
          isSucceed: { $set: null }
        }
      })
    case GET_ALL_TRANSACTION_LIST_SUCCESS:
      return update(state, {
        export: {
          isLoading: { $set: false },
          isSucceed: { $set: true }
        }
      })
    case GET_ALL_TRANSACTION_LIST_FAILED:
      return update(state, {
        export: {
          isLoading: { $set: false },
          isSucceed: { $set: false }
        }
      })
    case GET_ALL_TRANSACTION_LIST_CLEAR:
      return update(state, {
        export: {
          isLoading: { $set: false },
          message: { $set: '' },
          isSucceed: { $set: null }
        }
      })
    case GET_DETAIL_TRANSACTION_START:
      return update(state, {
        detailTrx: {
          data: { $set: {} },
          isLoading: { $set: true },
          message: { $set: "" }
        }
      })
    case GET_DETAIL_TRANSACTION_SUCCESS:
      return update(state, {
        detailTrx: {
          data: { $set: action.payload },
          isLoading: { $set: false },
        }
      })
    case GET_DETAIL_TRANSACTION_FAILED:
      return update(state, {
        detailTrx: {
          isLoading: { $set: false },
          message: { $set: action.payload }
        }
      })
    case GET_RETENTION_DETAIL:
      return update(state, {
        retentionDetail: {
          data: { $set: action.payload }
        }
      })
    case GET_TRANSFER_METHOD:
      return update(state, {
        getTransferMethod: {
          trfMethod: { $set: action.payload }
        }
      })
    case GET_TRANSFER_METHOD_CLEAR:
      return update(state, {
        getTransferMethod: {
          trfMethod: { $set: [] }
        }
      })
    case SAVE_TRANSFER_BENEFICIARY:
      return update(state, {
        trfBeneficiary: {
          data: { $push: [action.payload] }
        }
      })
    case SAVE_TRANSFER_BENEFICIARY_CLEAR:
      return update(state, {
        trfBeneficiary: {
          data: { $set: [] }
        }
      })
    case SAVED_BENEFICIARIES_TYPE:
      return update(state, {
        savedBeneficiaryType: { $set: action.payload }
      })
    case GET_TEMP_GROUP:
      return update(state, {
        tempGroup: {
          data: { $set: action.payload }
        }
      })
    case GET_TEMP_GROUP_CLEAR:
      return update(state, {
        tempGroup: {
          data: { $set: [] }
        }
      })
    default:
      return state;
  }
}

export default payoutReducer;
